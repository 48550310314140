.job-function-container {
  max-width: 680px;
  margin: 0 auto;
  margin-top: 20px;  
}

.job-function-button {
  width: 100%;
  margin-top: 15px;
}

.job-function-button[disabled], .job-function-button[disabled]:hover, .job-function-button[disabled]:focus {
  opacity: 0.6;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}

.job-function-form input {
  margin-bottom: 3px;
}