.list-candidate-container {
  margin: 0 auto;
  margin-top: 20px;  
  padding: 0px 25px 0px 25px;
}

.list-candidate-form-button {
  width: 100%;
  margin-top: 15px;
}

.list-candidate-form-button[disabled], .list-candidate-form-button[disabled]:hover, .list-candidate-form-button[disabled]:focus {
  opacity: 0.6;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}

.list-candidate-form input {
  margin-bottom: 3px;
}

.dnd {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.wrapper-dnd {
  overflow: auto;
}

.list-candidate-download-filter {
  margin-top: 7px;
  margin-left: 7px;
  margin-bottom: 3px;
}

.form-candidate-filter {
  margin-left: 7px;
}

.modal-responsive {
  position: relative;
  width: auto !important;
  margin: 0.5rem;
}
@media (min-width: 576px) {
  .modal-responsive {
    max-width: 500px;
    margin: 1.75rem auto;
  }
}

@media (min-width: 992px) {
  .modal-responsive {
    max-width: 800px;
  }
}