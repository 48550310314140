.view-vacancy-container {
    max-width: 1080px;
    margin: 0 auto;
    margin-top: 20px;  
  }
  
  .view-form-button {
    width: 100%;
    margin-top: 15px;
  }
  
  .view-form-button[disabled], .view-form-button[disabled]:hover, .view-form-button[disabled]:focus {
    opacity: 0.6;
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
  }
  
  .view-form input {
    margin-bottom: 3px;
  }