.view-candidate-container {
  max-width: 680px;
  margin: 0 auto;
  margin-top: 20px;  
}

.view-candidate-form-button {
  width: 100%;
  margin-top: 15px;
}

.view-candidate-form-button[disabled], .view-candidate-form-button[disabled]:hover, .view-candidate-form-button[disabled]:focus {
  opacity: 0.6;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}

.view-candidate-form input {
  margin-bottom: 3px;
}