@media only screen and (min-width: 1299px) {
  .section-year-title {
    display: block !important;
  }
}

@media only screen and (max-width: 1599px) {
  .main-header .nav-outer {
    padding-left: 100px;
  }
  .main-header .header-upper .outer-box .mobile-nav-toggler {
    margin-left: 25px;
  }

  .page-banner-section.style-two .title {
    margin-bottom: 180px;
  }
  .page-banner-section {
    position: relative;
    height: 110vh;
  }
}

@media only screen and (max-width: 1199px) {
  .main-header .header-upper .language-list li {
    margin-right: 35px;
  }

  .main-header {
    margin: 0px !important;
  }

  .main-header .outer-box .buttons-box .theme-btn {
    font-size: 16px;
  }

  .main-header .header-upper .outer-container {
    padding: 0px 15px;
  }

  h1 {
    font-size: 80px;
  }

  .main-header .header-upper .outer-box .mobile-nav-toggler {
    margin-left: 15px;
  }

  .main-footer .outer-container {
    padding: 0px 15px;
  }

  .about-section .content-column .inner-column {
    padding-right: 0px;
  }

  .fluid-section-one .content-column .inner-column {
    padding-right: 15px;
  }

  .fluid-section-one .image-column {
    width: 50%;
  }

  .project-detail-section .carousel-box .owl-nav {
    left: 30px;
    right: 30px;
  }

  .searvice-section {
    margin-top: 120px;
  }
  .projects-section,
  .about-section,
  .testimonial-section {
    padding: 110px 0px 0px;
  }

  .main-footer {
    padding-top: 80px;
  }
  .main-footer .footer-bottom {
    margin-top: 25px;
  }

  .main-footer h3 {
    font-size: 24px;
  }

  .project-detail-section {
    padding: 115px 0px 0px;
  }

  .page-banner-section.style-two .banner-year {
    display: none;
  }

  .page-banner-section.style-two .title {
    margin-bottom: 100px;
  }

  .page-banner-section {
    padding-top: 300px;
    position: relative;
    height: 125vh;
  }

  .page-banner-section h1 {
    font-size: 3.5em !important;
  }
}

@media only screen and (max-width: 991px) {
  .main-header .header-upper .outer-box .mobile-nav-toggler {
    padding: 56px 0px;
  }

  .main-footer .copy-column .copyright {
    text-align: center;
    margin-bottom: 15px;
  }

  .main-footer .language-column ul {
    text-align: center;
    margin-top: 10px;
  }

  .btn-style-one {
    font-size: 16px;
    padding: 10px 30px;
  }

  .fluid-section-one .content-column {
    width: 100%;
    margin-bottom: 60px;
  }

  .fluid-section-one .content-column .inner-column {
    padding-right: 15px;
    max-width: 100%;
  }

  .fluid-section-one .image-column {
    position: relative;
    width: 100%;
    background-image: none !important;
  }

  .fluid-section-one .image-column .image-box {
    display: block;
  }

  .main-header .nav-outer .mobile-nav-toggler {
    display: block;
    font-size: 30px;
    color: #ffffff;
  }

  .main-header .nav-outer,
  .main-menu {
    display: none;
  }

  .main-header .header-upper .language-list {
    padding: 56px 0px;
  }

  .searvice-section {
    margin-top: 70px;
  }
  .about-section {
    padding: 60px 0px 0px;
  }
  .projects-section,
  .testimonial-section {
    padding: 70px 0px 0px;
  }

  .about-section.about-style-tow {
    padding-top: 80px;
  }

  .fluid-section-one .image-column .image-box {
    margin-bottom: 0;
  }

  .about-section .content-column .inner-column {
    margin-bottom: 50px;
  }
  .about-style-tow .content-column .inner-column {
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    margin-bottom: 0;
  }

  .testimonial-section.style-two {
    margin-top: 80px;
  }

  .project-detail-section {
    padding: 75px 0px 0px;
  }

  .arrow-icon {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .arrow-desktop {
    display: none;
  }

  .page-banner-section h1 {
    font-size: 3em !important;
  }

  .page-banner-section {
    position: relative;
    height: 120vh;
  }
  
  .gptw p {
    font-size: 1em;
  }
  
  .gptw h4 {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 60px;
  }

  .main-header .nav-outer {
    padding-right: 0px;
  }

  .header-upper .logo-outer {
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    margin: 0px;
  }

  .main-header .header-upper .outer-box {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 12;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 0px;
  }

  .main-header .main-box .logo-box {
    float: none;
    text-align: center;
    padding-bottom: 10px;
  }

  .main-header .header-lower .outer-box {
    position: absolute;
    z-index: 12;
    display: block;
  }

  .main-header .header-upper .search-box-outer .dropdown-menu {
    top: 51px !important;
    left: 0px;
    right: auto;
  }

  .main-header .header-upper .logo-box {
    z-index: 20;
    padding: 12px 0px;
  }

  .main-header .header-top .top-left {
    padding-right: 0px;
    width: 100%;
  }

  .main-header .header-top .info-list li a {
    font-size: 13px;
  }

  .main-header .header-top .info-list li {
    margin-right: 12px;
  }

  h2 {
    font-size: 34px;
  }

  .list-style-one {
    margin-bottom: 40px;
  }

  h4 {
    font-size: 20px;
  }

  .main-header .nav-outer .mobile-nav-toggler {
    display: block;
    font-size: 30px;
    color: #ffffff;
  }

  .header-style-two .nav-outer .mobile-nav-toggler {
    color: #111111;
  }

  .main-header.fixed-header .header-upper .logo-box {
    padding: 14px 0px;
  }

  .project-detail-section p {
    font-size: 16px;
  }

  .main-header .header-upper .outer-box .mobile-nav-toggler,
  .main-header .header-upper .language-list {
    padding: 35px 0px;
  }

  .recruitment-mobile {
    display: block !important;
  }

  .recruitment-desktop {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .sec-title h2 {
    font-size: 30px;
  }

  h1 {
    font-size: 40px;
  }

  .projects-section .sec-title .pull-right {
    width: 100%;
  }

  .projects-section .sec-title .projects {
    padding-top: 0px;
    margin-top: 25px;
  }

  .projects-section .owl-dots .owl-dot {
    margin-top: 0;
  }

  .main-footer .footer-column:first-child,
  .main-footer .footer-column {
    width: 100%;
  }

  .project-detail-section .carousel-box .owl-dots {
    bottom: 15px;
  }

  .testimonial-section.style-two {
    margin-top: 110px;
  }

  .main-footer .footer-bottom {
    margin-top: 50px;
  }

  .searvice-section {
    margin-top: 50px;
  }
  .about-section {
    padding: 42px 0px 0px;
  }
  .projects-section,
  .testimonial-section {
    padding: 50px 0px 0px;
  }

  .page-banner-section h1 br {
    display: none;
  }

  .page-banner-section h1 {
    font-size: 3.5em;
  }

  .main-footer {
    padding-top: 60px;
  }
  .main-footer .footer-bottom {
    margin-top: 25px;
  }

  .fluid-section-one .image-column .image-box {
    margin-bottom: 0;
  }

  .about-section .content-column .inner-column {
    margin-bottom: 50px;
  }
  .about-section.about-style-tow {
    padding-top: 60px;
  }
  .about-style-tow .content-column .inner-column {
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    margin-bottom: 0;
  }

  .testimonial-section.style-two {
    margin-top: 60px;
  }

  .project-detail-section {
    padding: 55px 0px 0px;
  }

  .project-detail-section h3 {
    margin-bottom: 20px;
  }
  .sec-title {
    margin-bottom: 40px;
  }
  .new-projects-section .post {
    padding: 30px 0px;
  }
  .new-projects-section a {
    font-size: 16px;
  }

  .recruitment-mobile {
    display: block !important;
  }

  .recruitment-desktop {
    display: none;
  }

  .gptw p {
    font-size: 0.75em;
  }
  
  .gptw h4 {
    font-size: 1.25em;
  }
}

@media only screen and (max-width: 479px) {
  .btn-style-one {
    font-size: 14px;
    padding: 8px 25px;
  }

  .main-header .header-upper .language-list li {
    margin-right: 20px;
  }
  .main-header .header-upper .language-list li a {
    font-size: 13px;
  }
  .main-header .header-upper .outer-box .mobile-nav-toggler {
    margin-left: 0;
  }

  .testimonial-section .owl-nav {
    position: relative;
    top: 0px;
    margin-top: 10px;
  }

  .testimonial-section .owl-nav .owl-prev,
  .testimonial-section .owl-nav .owl-next {
    margin-left: 0px;
    margin-right: 25px;
  }

  .logo img {
    height: 20px !important;
  }
  h1 {
    font-size: 30px !important;
    letter-spacing: -1px !important;
  }

  h2 {
    font-size: 34px;
  }

  .testimonial-block .inner-box {
    padding: 25px 25px;
  }

  .about-section .image-column .inner-column .pattern-layer {
    left: 0;
    width: 236px;
    height: 236px;
  }
  .about-section .image-column .image {
    padding-left: 0px;
  }

  .page-banner-section h1 {
    font-size: 2em !important;
  }

  .btn-style-one {
    padding: 4px 20px;
  }

  .recruitment-mobile {
    display: block !important;
  }

  .recruitment-desktop {
    display: none;
  }

  .competencies-table .ant-table-thead > tr > th:nth-child(2),
  .competencies-table .ant-table-thead > tr > th:nth-child(3),
  .vacancy-table .ant-table-thead > tr > th:nth-child(3){
    width: 20%;
  }

  .vacancy-table .ant-table-tbody tr > td:nth-child(3) > a > p:hover {
    padding: 3px 10px;
  }
}
