.text-white {
  color: white !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-center {
  text-align: center !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.ls-1 {
  letter-spacing: -1px;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.bordered-layout .page-wrapper {
  padding: 0px 50px 0px;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #440c91;
}

button,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: normal;
  margin: 0px;
  background: none;
  line-height: 1.6em;
  font-family: 'Inter', sans-serif;
}

h2 {
  letter-spacing: -2px;
  font-weight: 600 !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

/* Typography */

h1 {
  font-size: 100px;
}

h2 {
  font-size: 42px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

textarea {
  overflow: hidden;
}

p {
  position: relative;
  line-height: 1.8em;
  font-size: 16px;
}

.arrow-icon {
  display: none;
}



.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 60px;
  margin: 0 auto;
}

.table-container {
  position: static;
  max-width: 1200px;
  padding: 0px 20px;
  margin: 0 auto;
}

.medium-container {
  max-width: 850px;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
}

ul,
li {
  list-style: none;
  padding: 0 5px;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

.theme-btn {
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.centered {
  text-align: center;
}

/*Btn Style One*/

.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 35px;
  font-weight: 400;
  overflow: hidden;
  border-radius: 25px;
  background-color: #00ab7d;
  text-transform: uppercase;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  z-index: 2;
}

.btn-style-one:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background-color: #0a8564;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0.2, 1);
  transform: scale(0.2, 1);
}

.btn-style-one i {
  position: relative;
  top: 1px;
  margin-left: 4px;
}

.btn-style-one .txt {
  position: relative;
  z-index: 1;
}

.btn-style-one:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-style-one:hover {
  color: #ffffff;
}

/* Btn Style Two */

.btn-style-two {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 30px;
  color: #6200c0;
  padding: 5px 26px;
  font-weight: 600;
  overflow: hidden;
  border-radius: 25px;
  background-color: transparent;
  border: 0;
}

.btn-style-two:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0.2, 1);
  transform: scale(0.2, 1);
}

.btn-style-two i {
  position: relative;
  top: 1px;
  margin-left: 4px;
}

.btn-style-two .txt {
  position: relative;
  z-index: 1;
}

.btn-style-two:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-style-two:hover {
  color: #ffffff !important;
  border-color: #6200c0;
  background-color: #6200c0;
  transition: 0.3s all ease;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title {
  position: relative;
  margin-bottom: 30px;
}

.sec-title .title {
  position: relative;
  color: #666666;
  font-size: 18px;
  line-height: 1.8em;
  letter-spacing: 1px;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.sec-title h2 {
  position: relative;
  color: #000000;
  font-weight: 400;
  line-height: 1.2em;
}

.sec-title .text {
  position: relative;
  color: #666666;
  font-size: 18px;
  line-height: 1.8em;
  margin-top: 20px;
}

.sec-title.centered {
  text-align: center !important;
}

.sec-title.light .title {
  color: #ffe1a0;
  background-color: #ffffff;
}

.sec-title.light h2 {
  color: #ffffff;
}

/* List Style One */

.list-style-one {
  position: relative;
  z-index: 1;
}

.list-style-one li {
  position: relative;
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 6px;
  padding-left: 30px;
  line-height: 1.8em;
}

.list-style-one li:before {
  position: absolute;
  left: 0px;
  top: 10px;
  width: 7px;
  height: 7px;
  content: '';
  display: block;
  font-weight: 400;
  border-radius: 50px;
  background-color: #666666;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header {
  position: absolute;
  z-index: 999;
  width: 100%;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.h-50 {
  height: 50% !important;
}

.main-header .header-upper {
  position: relative;
  z-index: 1;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-header .header-upper .inner-container {
  position: relative;
}

.header-style-two .main-menu .navigation > li:before {
  background-color: #000000;
}

.main-header .header-upper .logo-box {
  position: relative;
  z-index: 10;
  padding: 33px 0px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-header .header-upper .logo-box .logo {
  position: relative;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.logo img {
  height: 30px !important;
}

/*** 

====================================================================
	Page Banner Section
====================================================================

***/

.page-banner-section {
  position: relative;
  height: 100vh;
  padding: 40vh 0px 80px;
  background-attachment: fixed;
  background-size: cover;
}
.page-banner-section::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.page-banner-section .auto-container {
  position: relative;
}

.page-banner-section .title {
  position: relative;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 30px;
  line-height: 1.6em;
  text-transform: uppercase;
}

.page-banner-section h1 {
  position: relative;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.1em;
  text-transform: capitalize;
  font-size: 5em;
  letter-spacing: -3px;
}


/*** 

====================================================================
	About Section
====================================================================

***/

.about-section {
  position: relative;
  padding: 50px 0;
  background-color: #f9f9f9;
}

.about-style-tow {
  padding-top: 160px;
}

.about-section .section-year-title {
  position: absolute;
  left: 50px;
  top: 170px;
  color: #999999;
  font-size: 14px;
  text-transform: uppercase;
  transform: rotate(-90deg);
}

/*** 

====================================================================
	Fluid Section One
====================================================================

***/

.fluid-section-one {
  position: relative;
  overflow: hidden;
  padding: 0px 0px 0px;
}

/* Service Box */

.searvice-section {
  margin-top: 160px;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section {
  position: relative;
  padding: 100px 0;
  background-color: #eef2f2;
}

.testimonial-section.style-two {
  margin-top: 160px;
  background-color: #eef2f2;
}

.testimonial-section .sec-title {
  margin-bottom: 70px;
}

.testimonial-block {
  position: relative;
  margin-bottom: 30px;
}

.testimonial-block .inner-box {
  position: relative;
  padding: 25px 32px 35px;
  border: 1px solid #e1e1e1;
  background-color: #ffffff;
}

.testimonial-block .inner-box:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  right: 0px;
  height: 5px;
  opacity: 0;
  transform: scale(0.3, 1);
  background-color: #553f6a;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.testimonial-block .inner-box:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}

.testimonial-block .inner-box .quote {
  position: relative;
  font-size: 60px;
  color: #553f6a;
  line-height: 1em;
  text-align: center;
  margin-right: -10px;
}

.testimonial-block .inner-box .author {
  position: relative;
  color: #263a4f;
  font-size: 18px;
  font-weight: 500;
  margin-top: 0px;
  font-family: 'Heebo', sans-serif;
}

.testimonial-block .inner-box .author span {
  position: relative;
  font-weight: 500;
  color: #4d5964;
  font-size: 18px;
}

.testimonial-block .inner-box p {
  position: relative;
  color: #4d5964;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 0px;
}

/*** 

====================================================================
	Project Detail Section
====================================================================

 ***/

.project-detail-section,
.job-section {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #777777;
  line-height: 1.7em;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}

.project-detail-section {
  position: relative;
  padding: 80px 0px 0px;
}

.project-detail-section .upper-box {
  position: relative;
  margin-bottom: 80px;
}

.project-detail-section .upper-box .back-projects {
  position: relative;
  color: #000000;
  font-size: 14px;
  margin-bottom: 55px;
  display: inline-block;
  text-transform: uppercase;
}

.project-detail-section .upper-box .back-projects .icon {
  position: relative;
  top: 4px;
  margin-right: 10px;
  font-size: 20px;
  line-height: 1em;
}

.project-detail-section .upper-box ul {
  position: relative;
}

.project-detail-section .upper-box ul li {
  position: relative;
  color: #999999;
  font-size: 16px;
  margin-bottom: 8px;
}

.project-detail-section .upper-box ul li span {
  position: relative;
  color: #000000;
}

.project-detail-section h3 {
  position: relative;
  color: #000000;
  margin-bottom: 40px;
  text-transform: capitalize;
}

.project-detail-section p {
  position: relative;
  color: #666666;
  font-size: 18px;
  margin-bottom: 20px;
}

/*** 

====================================================================
	Reservation Section
====================================================================

***/

.benefits h4 {
  font-weight: 600 !important;
  color: #3d2a68 !important;
  letter-spacing: -1px;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.benefits p {
  font-size: 14px !important;
  line-height: 25px;
}

.img-circle {
  border-radius: 50%;
  width: 200px !important;
  height: 200px !important;
  object-fit: cover;
  margin: 0 auto;
}
.jobs {
  padding: 100px 0 0 0;
}
.jobs p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.v-center td {
  vertical-align: middle;
}

.jobs .sec-title {
  margin-bottom: 20px;
}

.jobs h3,
.process h4 {
  font-size: 2.2em;
  font-weight: 600;
  color: #422959;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.8;
}

.page-item.active .page-link,
.btn-primary {
  background-color: #6200c0;
  border-color: #6200c0;
  font-weight: 600;
}

.btn-primary:hover {
  background-color: #2f0c4b;
  border-color: #000000;
}

.page-link {
  color: #6200c0;
}

.project-detail-section li {
  list-style: disc;
  margin: 0 15px;
}
.application-forms label {
  color: #555555;
  font-weight: bold;
}
.application-forms .form-group {
  margin-bottom: 15px;
}

.application-forms h4 {
  letter-spacing: -1px;
  font-weight: bold;
  color: #333;
}

.btn-outline-primary {
  border-color: #6200c0;
  font-size: 12px;
  font-weight: 500;
  color: #6200c0;
}
.btn-outline-primary:hover {
  border-color: #6200c0;
  color: #ffffff;
  background-color: #6200c0;
}
.ls-1 {
  letter-spacing: -1px;
}

/***

====================================================================
	Job
====================================================================

***/

.ant-table-column-title {
  font-weight: bold;
}

.ant-table-thead > tr > th {
  background-color: white;
  word-break:keep-all;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px 14px;
}

.ant-table-tbody tr > td:nth-child(3) > a > p:hover {
  color: #ffffff !important;
  border-color: #6200c0;
  background-color: #6200c0;
  transition: 0.3s all ease;
  border-radius: 25px;
  line-height: 35px;
  padding: 3px 20px;
}

.tr:hover > td {
  background: green;
}

.ant-pagination-item {
  border: 1px solid #dee2e6;
  border-radius: 0;
  min-width: 36px;
  height: 39px;
  line-height: 39px;
  margin-top: 7px;
}

.ant-pagination-item-active {
  background-color: #5901ad;
  background: #5901ad;
  color: white;
}

.ant-pagination-item .ant-pagination-item-active:focus,
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a,
.ant-pagination-item-active a {
  color: white;
}

.ant-alert-warning {
  background-color: #fff3cd !important;
}

.ant-alert-description {
  font-size: 16px !important;
  line-height: 1.8em !important;
  position: relative;
  padding-left: 90px;
}

.ant-table .ant-table-tbody > tr:hover > td {
  background: rgb(238, 237, 237) !important;
}

.ant-alert-warning-icon {
  position: absolute;
  width: 125px !important;
  height: 125px !important;
  top: 6px !important;
}

.ant-alert-message {
  font-size: 16px !important;
  line-height: 1.8em !important;
  font-weight: bold;
}
/***

====================================================================
	Personal Detail Form
====================================================================

***/

.ant-form-item-required::before {
  display: none;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-form-vertical .ant-form-item {
  margin-bottom: 0;
}

.ant-form-item {
  margin-bottom: 8px !important;
}

.ant-btn-primary:hover {
  background-color: #6200c0 !important;
  color: white !important;
}

/***

====================================================================
	Form Floating
====================================================================

***/

.form-floating {
  position: relative;
  margin-bottom: 1rem;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size='1']) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #999999;
}
.form-control::-webkit-input-placeholder {
  color: #999999;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.job-detail-divider.ant-divider-horizontal {
  height: 5px !important;
  width: 60px;
  min-width: 7%;
  background: #3ec3a4 !important;
}

.gptw {
  display: flex;
  align-items: flex-start;
}

.gptw img {
  width: 60px;
  margin-right: 20px;
  margin-left: -4px;
}

.gptw p {
  margin-bottom: 0px;
  color: #00ab7d;
  font-weight: bold;
}

.gptw h4 {
  color: #ffffff;
  font-weight: bold;
}

.testimonial-navigation {
  width: 100%;
  margin-bottom: 10px;
  margin-top: -40px;
}

.testimonial-navigation > img {
  margin-left: 5px;
  cursor: pointer;
  /* pointer-events: none; */
  opacity: 0.35;
}

.testimonial-navigation > img:hover {
  opacity: 0.8;
}

.testimonial-navigation:after {
  content:'';
  display:block;
  clear: both;
}

.testimonial-content-container {
  padding-top: 30px;
  min-height: 206px;
}

.testimonial-content-container p {
  vertical-align: middle;
}