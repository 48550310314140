.update-template-container {
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 20px;  
}

.update-template-form-button {
  width: 100%;
  margin-top: 15px;
}

.update-template-form-button[disabled], .update-template-form-button[disabled]:hover, .update-template-form-button[disabled]:focus {
  opacity: 0.6;
  color: #fff;
  background-color: #1890ff;
  border-color: #1890ff;
}

.update-template-form input {
  margin-bottom: 3px;
}