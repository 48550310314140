/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer {
  position: relative;
  padding-top: 120px;
}

.main-footer.style-two {
  border-top: 1px solid #dbdbdb;
}

.main-footer .outer-container {
  position: relative;
  padding: 0px 60px;
}

.main-footer h3 {
  position: relative;
  font-weight: 400;
  color: #000000;
  font-size: 30px;
  line-height: 1.3em;
  margin-bottom: 28px;
}

.main-footer .contact-widget ul li {
  position: relative;
  color: #666666;
  font-size: 14px;
  max-width: 300px;
  line-height: 1.9em;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.main-footer .contact-widget ul li a {
  position: relative;
  color: #666666;
  overflow-wrap: break-word;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .contact-widget ul li a:hover {
  color: #000000;
}

/* Link Widget */

.main-footer .link-widget ul li {
  position: relative;
  color: #666666;
  font-size: 14px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.main-footer .link-widget ul li a {
  position: relative;
  color: #666666;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .link-widget ul li a:hover {
  color: #000000;
  padding-left: 15px;
}

.main-footer .footer-bottom {
  position: relative;
  padding: 30px 0px;
  margin-top: 85px;
  border-top: 1px solid #f0f0f0;
}

/* Copyright Column */

.main-footer .copy-column {
  position: relative;
}

.main-footer .copy-column .copyright {
  position: relative;
  color: #999999;
  font-size: 14px;
  font-weight: 400;
}

.main-footer .copy-column .copyright a {
  position: relative;
}

.main-footer .copy-column .copyright span {
  position: relative;
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
}

.main-footer .copy-column .copyright i {
  position: relative;
  color: #f53400;
  font-size: 16px;
}

/* Pages Column */

.main-footer .pages-column {
  position: relative;
}

.main-footer .pages-column .page-nav {
  position: relative;
  text-align: center;
}

.main-footer .pages-column .page-nav li {
  position: relative;
  margin: 0px 6px;
  display: inline-block;
}

.main-footer .pages-column .page-nav li a {
  position: relative;
  color: #000000;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .pages-column .page-nav li a:hover {
  color: #00ccff;
}

/* Language Column */

.main-footer .language-column {
  position: relative;
}

.main-footer .language-column ul {
  position: relative;
  text-align: right;
}

.main-footer .language-column ul li {
  position: relative;
  margin-left: 30px;
  display: inline-block;
}

.main-footer .language-column ul li a {
  position: relative;
  color: #8d9aa8;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .language-column ul li a:hover {
  color: #263a4f;
}

@media only screen and (min-width: 1199px) {
  .term-condition {
    text-align: right !important;
  }
  .copyright {
    text-align: left !important;
  }
}

.term-condition,
.copyright {
  text-align: center;
}
