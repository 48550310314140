.pg-viewer-wrapper {
  overflow: auto !important;
}

.pg-viewer-wrapper img,
.photo-viewer-container {
  width: auto !important;
  height: auto !important;
}

.pg-viewer-wrapper > .photo-viewer-container {
  width: auto !important;
  height: auto !important;
}

.width-modal-v1 {
  max-width: 800px;
  width: auto !important;
}